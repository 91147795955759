.infoNosotros{
    row-gap: 10px;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    flex: 1;
    max-height: 500px;
    padding: 10px;
    margin-top: 20px;
}
.infoNosotros-numeros{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.numeros-construidos{
    width: 300px;
    .timer{
        text-align: center;  
        font-size: 30px;  
        font-weight: 600;
    }
    p{
        text-align: center;
        font-size: 20px;
    }
}
.numeros-construidos:first-child{
    margin-bottom: 30px;
}
.infoNosotros-descripcion{
    margin: 0 auto;
    max-width: 80%;
    p{
        text-align: center;
        font-size: 20px;
    }

}
@media(min-width : 600px){
    .infoNosotros{
        margin: 30px 0;
        max-height: 600px;
    }
    .numeros-construidos{
        width: 450px;
        .timer{
            font-weight: 600;
            text-align: left;  
            font-size: 60px;  
        }
        p{
            text-align: left;
            font-size: 30px;
        }
    } 
    .infoNosotros-descripcion{
        max-width: 100%;
        p{
            font-size: 30px;
        }
    }
}
@media(min-width : 850px){
    .infoNosotros{
        padding: 10px;
        height: 100vh;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
    .numeros-construidos:first-child{
        margin-bottom: 100px;
    }
}