footer{
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.footer-datos{
    margin-top: 10px;
    justify-content: center;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
@media(min-width:669px){
    .footer-datos{
        flex-direction: row;
    }
}
.footer-logo{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 200px;
    }
}
.footer-contacto{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p{
        text-align: center;
        margin-bottom: 20px;
    }
}
.footer-redes{
    margin: 20px 0;
    svg{
        margin: 10px;
        width: 30px;
    }
}
.botonWhatsapp{
    z-index: 1100;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    img{
        width: 100%;
        height: 100%;
    }
}
