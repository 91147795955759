:root {
    --negro: #1d1d1d;
    --grisClaro: #53575a;
    --blanco: #efefef;
    --amarillo:#f4e962;
}
*,html, body{
    scroll-behavior:smooth;
    padding: 0;
    margin: 0;
    font-family: "Inter", sans-serif;
}
h1,h2,h3,h4{
    text-align: center;
}
