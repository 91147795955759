.contactanos{
    margin-top: 50px;
    h2{
        font-size: 35px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 60px;
    }
}
.contactanos-datos{
    display: grid;
    grid-template-columns: 1fr;
    height: 500px;
    background-color: white;
}

.contactanos-imagen{
    display: none;
    width: 100%;
    height: 500px;
    img{
        width: 100%;
        height: 100%;
    }
}
@media(min-width: 600px){
    .contactanos-imagen{
        display: unset;
    }
    .contactanos-datos{

        grid-template-columns: 1fr 1fr;
    }
}
@media(min-width: 769px){
    .contactanos-datos{

        grid-template-columns: .5fr 1fr;
    }
}
.mapa{
    width: 100%;
    height: 400px;
}
.datos-ubi{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    p{
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
    }
}
.contacto-botonWhatsapp{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    gap: 10px;
    margin: 0 auto;
    text-decoration: none;
    color: white;
    background-color: rgb(8, 170, 8);
    padding: 15px;
    border-radius: 5px;
    font-weight: 500;
}
.contacto-botonWhatsapp:hover{
    background-color: rgb(13, 206, 13);
}