.marcas-container{
    position: relative;
    overflow: hidden;
    padding: 60px 0 ;
    margin-bottom: 80px;
    background: rgb(255, 255, 255);
    display: flex;

}
.marcas-container:before,
.marcas-container:after{
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    content :"";
    z-index: 2;
}
.marcas-container:before{
    left: 0;
    background: linear-gradient(to left, rgba(255,255,255,0),white);
}
.marcas-container:after{
    right: 0;
    background: linear-gradient(to right, rgba(255,255,255,0),white);
}
@media(min-width: 769px){
    .marcas-container:before,
    .marcas-container:after{
        width: 250px;
    }
}
.marcas{
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 15s scroll infinite linear;
    img{
        width: 250px;
        margin: 0 40px;
    }
}
@keyframes scroll{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}
