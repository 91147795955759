.navegador{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    background-color: whitesmoke;
    top: 0;
    right: 0;
    z-index: 1001;
    width: 100%;
    padding: 10px;
    background-color: rgba(0,0,0,0);
    transition: background-color .5s ease;
}

.navegador_opciones{
    display: none;
    height: 100%;
    width: 100%;
    flex: 1;
    align-items: center;
    ul{
        width: 100%;
        list-style: none;
        display: flex;
        justify-content: right;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    li{
        color: white;
    }
    li a{
        font-weight: 400;
        text-decoration: none;
        font-size: 15px;
        color: white;
    }
}

.navegador_logo{
    width: 120px;
    padding:  5px 20px;
    img{
        width: 100%;
        height: 100%;
    }
}
.navegador_boton{
    position: absolute;
    top: 5px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 35px;
    padding:  5px 20px;
    img{
        width: 100%;
        height: 100%;
    }
}
@media(min-width: 769px){
    .navegador_boton{
        position: relative;
        top: unset;
        right: unset;
    }
    .navegador{
        width: 100%;
        height: 50px;
        flex-direction: row;
    }
    .navegador_opciones ul{
        justify-content: center;
        flex-direction: row;
    }
}