.nosotrosInfo{
    margin-top: 40px;
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1.5fr;
}
@media(min-width: 769px){
    .nosotrosInfo{
        height: 100vh;
        grid-template-columns: 1fr 1.5fr;
        grid-template-rows: 1fr;
    }   
}
.gale-img{
    position: relative;
    transition: height 1s ease;
    width: 100%;
    height: 25%;
    background-position: center;
}
.gale-img:nth-child(1){
    background-image: url(../img/imagenesProyectos/DJI_0165.webp);
    background-repeat: no-repeat;
    background-size: cover;
}
.gale-img:nth-child(2){
    background-image: url(../img/imagenesProyectos/COLOTLAN-1.webp);
    background-repeat: no-repeat;
    background-size: cover;
}
.gale-img:nth-child(3){
    background-image: url(../img/imagenesProyectos/DJI_0209-2.webp);
    background-repeat: no-repeat;
    background-size: cover;
}
.gale-img:nth-child(4){
    background-image: url(../img/imagenesProyectos/DJI_0228-2.webp);
    background-repeat: no-repeat;
    background-size: cover;
}
.gale-img:hover{
    height: 100%;
}
.obscurecer:hover{
    background-color: rgba(0,0,0,.0);
}
.nosotrosInfo-gale{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 5px;
}
.nosotrosInfo-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    h2{
        font-size: 40px;
        margin-bottom: 30px;
    }
    p{
        text-align: center;
        font-size: 20px;
        margin-bottom: 20px;
        span{
            font-weight: bold;
        }
    }

}
.servicios{
    margin-top: 20px;
    li{
        font-size: 20px;
        span{
            font-weight: bold;
        }
    }
}
.casosExito{
    padding: 80px 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    h2{
        text-transform: uppercase;
        font-size: 40px;
        margin-bottom: 100px;
    }
}
.casosExito-Gal{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}
.gal-img{
    border-radius: 20px;
    width: 85%;
    position: relative;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    transition: display 1s ease;
    
}
.obscurecer2{
    border-radius: 20px;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 9 66 / 50%);
    transition: background-color 1s ease;
}
.img-desc{
    z-index: 3;
}
.img-desc h3{
    color: white;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}
.img-desc p{
    color: white;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
}
.gal-img:nth-child(1){
    background-image: url(../img/valores/web-1.webp);

}
.gal-img:nth-child(2){
    background-image: url(../img/imagenesProyectos/DJI_0221.webp);
}
.gal-img:nth-child(3){
    background-image: url(../img/imagenesProyectos/PrimerasBodegas-12.webp);
}
.gal-img:nth-child(4){
    background-image: url(../img/valores/RC-4.webp);
}
.gal-img:hover{
    .img-desc{
        display: none;
    }
    .obscurecer2{
        background-color: rgba(0,0,0,0);
    }
}

@media(min-width: 769px){
    .casosExito{
        height: 100vh;
    }
    .casosExito-Gal{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr ;
    }   
}