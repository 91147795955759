body {
    font-family: "Inter", sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
  }
  
  .container {
    overflow:hidden;
    overflow-wrap: break-word;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
    transition: background-color 2s ease;
  }
.imagenFondo{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(0);
    transition: transform 1s ease;

}
.imagenFondo img{
    width: 100%;
    height: 100%;

}
.imagenFondo.segF{
    opacity: 1;
    transform: scale(.4);
}
.obsureces{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.577);
    width: 100%;
    height: 100%;
    top: 0;
}
  .content {
    flex: 1;
    text-align: center;
  }
  
  .animated-element {
    flex: 1;
    text-align: center;
    opacity: 0; /* Inicialmente oculto */
    transform: translateY(20px); /* Desplazamiento inicial */
    transition: opacity 0.8s all, transform 0.8s all; /* Transiciones */
  }
  .titulo2{
    margin: 0;
    padding: 0;
    width: 250px;
    color: white;
    flex: 1;
    text-align: center;
    opacity: 0; /* Inicialmente oculto */
    transform: translateX(-1000px); /* Desplazamiento inicial */
    transition: opacity 0.8s ease, transform 1.5s ease, width 1s ease; /* Transiciones */
  }
  @media (min-width: 430px) {
    .titulo2{
      width: 500px;
    }
  }
  @media (min-width: 769px) {
    .titulo2{
      width: 800px;
    }
  }
  .titulo.show, .titulo2.show {
    opacity: 1; /* Mostrar cuando se activa la clase .show */
    transform: translateX(0); /* Volver a posición original */
  }
  .animated-element.show {
    opacity: 1; /* Mostrar cuando se activa la clase .show */
    transform: translateY(0); /* Volver a posición original */
  }
  
  .animated-element img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 8px; /* Opcional: dar borde redondeado a la imagen */
  }