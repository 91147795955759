.bannerNosotros{
    position: relative;
    height: 100vh;
    background-image: url(../img/imagenesProyectos/DJI_0203.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bannerNosotros-content{
    position: absolute;
    display: flex;
    justify-content: center;
    
    h1{
        color: white;

        font-size: 50px;
        
        bottom: 40%;
    }
}


