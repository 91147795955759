.sectionProeyctos{
    overflow: hidden;
    padding: 50px 10px;
    h2{
        text-transform: uppercase;
        margin: 40px 0 10px 0;
        font-size: 30px;
    }
    h3{
        font-size: 20px;
        font-weight: 300;
    }
    
}
.proyectosGaleria{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 50px 0;
}

.proyectosGaleria-Individual{
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    width: 70%;
    height: 250px;
    transition: width 1s ease;
}
@media(min-width: 769px){
    .proyectosGaleria{
        flex-direction: row;
    }
    .proyectosGaleria-Individual{
        width: 25%;
        height: 500px;
    }
    
}
.obscurecer{
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 1s ease;
}
.idividual-desc{
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    h2{
        font-weight: 600;
        color: white;
        font-size: 20px;
    }
}
.proyectosGaleria-Individual:hover{
    .obscurecer{
    background-color: rgba(0, 0, 0, 0);
    }
    .idividual-desc{
        display: none;
    }
}
.proyectosGaleria-Individual:nth-child(1){
    background-image: url(../img/imagenesProyectos/cpc.webp);
}
.proyectosGaleria-Individual:nth-child(2){
    background-image: url(../img/imagenesProyectos/DJI_0406.webp);
}
.proyectosGaleria-Individual:nth-child(3){
    background-image: url("../img/imagenesProyectos/PORTICO 01.avif");
}
#img1:hover, #img2:hover,#img3:hover{
    width: 100%;

}
