.ubicacion{
    height: 100%;
}
.ubicacionCont{
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1{
        text-transform: uppercase;
        margin: 10px 0 10px 0;
    }
    h3{
        font-weight: 300;
        font-size: 20px;
    }
}
.ubicacionMapa{
    border-radius: 20px;
    margin-top: 80px;
    padding: 1rem;
    width: 85%;
    height: 50vh;

}
.tituloMarcador{
    margin: 0;
    padding: 0;
    font-size: 20px;
    text-align: center;
}
.descripcionMarcador{
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 15px;
}
.negritas{
    font-weight: 600;
}