.form{
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    top: 0;
    background-color: rgba(0, 0, 0, 0.467);
    z-index: 1001;
    scroll-behavior: smooth;
}
#questionForm{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.preguntas{
    z-index: 1002;
    div{
        transition: display 1s ease;
    }
    position: relative;
    padding: 20px;
    row-gap: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 500px;
    background-color: white;
    border-radius: 10px;

}
.question{
    display: none;
    h2{
        margin-bottom: 40px;
    }
    h3{
        font-weight: 300;
        font-size: 20px;
    }
    input, select{
        padding: 10px;
        border: 2px solid gray;
        border-radius: 5px;
    }
}
.active{
    display: flex;
    flex-direction: column;
}
.navigation{
    button, #enviarData{
        color: white;
        background-color: black;
        border: none;
        padding: 10px;
        border-radius: 5px;
    }
}
#prevBtn, #enviarData{
    display: none;
}
.cerrarVentana{
    font-size: 20px;
    font-weight: 900;
    position: absolute;
    top: 15px;
    right: 20px;
}
