.textoDesplaza {
    overflow: hidden; /* Oculta el contenido que se desborda */
    width: 100%; /* Ajusta el ancho según sea necesario */
}

.textoContainer {
    display: flex; /* Contenedor flexible para alinear elementos en fila */
    animation: marquee 20s linear infinite; /* Animación 'marquee' por 20 segundos, lineal e infinita */
    margin: 10px 0;
    font-weight: 200;
}
.textoContainer2 {
    display: flex; /* Contenedor flexible para alinear elementos en fila */
    animation: marquee2 20s linear infinite; /* Animación 'marquee' por 20 segundos, lineal e infinita */
    font-weight: 600;
}

.textoContainer p, .textoContainer2 p {
    color: black; /* Color del texto */
    font-size: 30px; /* Tamaño de fuente */
    margin: 0; /* Elimina los márgenes por defecto */
    padding-right: 20px; /* Espacio entre repeticiones del texto */
    white-space: nowrap; /* Evita que los párrafos se envuelvan */
}

@keyframes marquee {
    0% { transform: translateX(0); } /* Comienza desde la posición inicial */
    100% { transform: translateX(-100%); } /* Finaliza después de haber mostrado todos los contenidos */
}
@keyframes marquee2 {
    100% { transform: translateX(0); } /* Comienza desde la posición inicial */
    0% { transform: translateX(-100%); } /* Finaliza después de haber mostrado todos los contenidos */
}

